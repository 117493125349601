<template>
  <div>
    <van-nav-bar :fixed="true" class="nav_bar_view" left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          alt
          @click="goRouter"
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">新增收货地址</span>
      </template>
    </van-nav-bar>

    <!--表单-->
    <div class="from_view">
      <div class="input_view">
        <span>联系人</span>
        <input type="text" v-model="contactPerson" placeholder="请填写联系人姓名" />
      </div>
      <div class="input_view">
        <span @click="toCodeSelection(2)">
          {{ label
          }}
          <van-icon name="arrow-down" style="margin-top: 3px; margin-left: 5px" />
        </span>
        <input type="text" v-model="phone" placeholder="请填写手机号码" />
      </div>
      <div class="add_address_view">
        <span class="code_view">验证码</span>
        <div>
          <input type="text" v-model="phoneCode" placeholder="请输入验证码" />
          <span class="get_code_view" @click="captcha_sendSms">获取验证码</span>
        </div>
      </div>
      <div class="address_view1" @click="toSearchAddress">
        <!-- <input type="text" placeholder="2"> -->
        <!-- <div class="address_text_view">
        </div>-->
        <span>收货地址</span>
        <!-- <span class="first_span_view">收货地址</span> -->
        <div>
          <input type="text" v-model="address" placeholder="请输入收货地址" />
          <van-icon class="img_view" size="22px" name="arrow" color="#CCCCCC" />
        </div>
      </div>
      <div class="xx_address_class">
        <span>详细地址</span>
        <input type="text" v-model="houseNumber" placeholder="门牌号，如，1栋2单元301" />
      </div>
      <div class="upload_photo_view">
        <!-- <img src="../../../assets/upload_img_bg.png" alt="" /> -->
        <van-uploader v-model="fileList" :after-read="afterRead" />
        <span>上传取菜地方图片</span>
      </div>
      <div class="switch_view">
        <span>设为默认地址</span>
        <van-switch
          style="margin-right: 12px"
          v-model="checked"
          active-color="#00B05A"
          size="46px 28px"
          inactive-color="#dcdee0"
        />
      </div>
    </div>
    <van-dialog
      v-model="show"
      style="font-size: 15px"
      width="310px"
      message="您未上传取菜地方图片，如果无法联系配送员，请让您的管家协助您取菜"
      confirm-button-text="我知道了"
      confirm-button-color="#1677FF"
    ></van-dialog>
    <div class="bottom_btn_sub_view">
      <van-button @click="saveInfo">保存</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

import {
  imgApp,
  app2,
  receiptAdd,
  captcha_sendSms,
  upload_upImg,
  geocoder
} from "@/service/api/index.js";

export default {
  name: "newAddress",
  data() {
    return {
      checked: false,
      show: false,
      phoneCode: "",
      contactPerson: "",
      address: "",
      houseNumber: "",
      phone: "",
      imgPath: "",
      isDefault: "",
      fileList: [],
      latitude: "",
      longitude: ""
    };
  },
  created() {
  },
  mounted() {
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.checked = false;
      this.show = false;
      this.phoneCode = "";
      this.contactPerson = "";
      this.address = "";
      this.houseNumber = "";
      this.phone = "";
      this.imgPath = "";
      this.isDefault = "";
      this.fileList = [];
    }
    this.$route.meta.isBack = false;
    if (this.$route.query) {
      this.address = this.$route.query.ress;
      this.longitude = this.$route.query.lng;
      this.latitude = this.$route.query.lat;
    }
  },
  computed: {
    label: {
      get() {
        return this.$route.query.code || "+63";
      }
    },
    countryCode: {
      get() {
        return this.$route.query.countryCode || "PH";
      }
    }
  },
  methods: {
    captcha_sendSms() {
      captcha_sendSms({
        phone: this.phone,
        areaCode: this.label.replace("+", ""),
        countryCode: this.countryCode
      }).then(res => {
        if (res.status == 200) {
          var btn = document.querySelector(".get_code_view");
          var sum = 60;
          var timer;
          timer = setInterval(() => {
            sum--;
            btn.innerHTML = sum + "s";
            if (sum === 0) {
              btn.innerHTML = "获取验证码";
              clearInterval(timer);
              sum = 60;
            }
          }, 1000);
        }
      });
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload_upImg(formData).then(res => {
        if (res.status == 200) {
          this.imgPath = res.data;
          this.fileList = [
            {
              url: app2 + "/" + res.data,
              isImage: true
            }
          ];
          Toast("上传成功");
        }
      });
    },
    receiptAdd() {
      // let obj = {
      //   address: "string",
      //   areaCode: "string",
      //   contactPerson: "string",
      //   countryCode: "string",
      //   createTime: "2021-05-10T13:53:09.929Z",
      //   free: "string",
      //   houseNumber: "string",
      //   id: 0,
      //   isDefault: "DISABLE",
      //   latitude: 0,
      //   longitude: 0,
      //   modifyTime: "2021-05-10T13:53:09.929Z",
      //   phone: "string",
      //   placeId: "string",
      //   takeImg: "string",
      //   userId: 0,
      // };
      // const { contactPerson, houseNumber, phone, address, isDefault } =
      //   this.form;
      if (this.checked) {
        this.isDefault = "ENABLE";
      } else {
        this.isDefault = "DISABLE";
      }
      receiptAdd({
        phone: this.phone,
        areaCode: this.label,
        countryCode: this.countryCode,
        contactPerson: this.contactPerson,
        address: this.address,
        houseNumber: this.houseNumber,
        isDefault: this.isDefault,
        takeImg: this.imgPath,
        captchaCode: this.phoneCode,
        latitude: this.latitude,
        longitude: this.longitude
      }).then(res => {
        // console.log(res);
        if (res.status == 200) {
          Toast("添加成功");
          // this.$router.push("/address");
          this.$router.go(-1);
        }
      }).catch(err=>{
        Toast(err.response.data.message);
      });
    },
    goRouter() {
      this.$router.go(-1);
    },
    saveInfo() {
      this.receiptAdd();
      // this.show = true;
    },
    toSearchAddress() {
      this.$router.replace({
        path: "/mapAddress",
        query: {
          code: this.label,
          countryCode: this.countryCode,
          form: "/newAddress"
        }
      });
    },
    toCodeSelection(option) {
      // this.$router.push({
      this.$router.replace({
        path: "/areaCodeSelection",
        query: { option: option, form: "/newAddress" }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .from_view {
    max-width: 540px;
  }
  .bottom_btn_sub_view {
    max-width: 540px;
  }
}
.from_view {
  width: 100%;
  position: fixed;
  background: white;
  top: 45px;
  .input_view {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    border-bottom: 0.5px solid #eeeeee;
    flex-direction: row;
    align-items: center;
    span {
      width: 86.5px;
      font-size: 15px;
      color: #333333;
    }
    input {
      width: 252.5px;
      height: 24px;
      border: none;
      font-size: 15px;
      margin-left: 15px;
      color: black;
    }
    input::input-placeholder {
      color: #cccccc;
    }
  }

  .add_address_view {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    border-bottom: 0.5px solid #eeeeee;
    // flex-direction: row;
    align-items: center;
    .code_view {
      width: 86.5px;
      font-size: 15px;
      color: #333333;
    }
    div {
      width: 252.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 13px;
      justify-content: space-between;
      input {
        width: 100px;
        height: 24px;
        border: none;
        font-size: 15px;
        color: black;
      }
      input::input-placeholder {
        color: #cccccc;
        font-size: 15px;
      }
      .get_code_view {
        font-size: 15px;
        color: #00b05a;
        margin-right: 30px;
      }
    }
  }

  .address_view1 {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    border-bottom: 0.5px solid #eeeeee;
    flex-direction: row;
    align-items: center;
    span {
      width: 86.5px;
      font-size: 15px;
      color: #333333;
    }
    div {
      width: 252.5px;
      display: flex;
      margin-left: 12px;
      align-items: center;
      input {
        width: 150px;
        height: 24px;
        border: none;
        font-size: 15px;
        color: black;
      }
      input::input-placeholder {
        color: #cccccc;
        font-size: 15px;
      }
    }

    // .img_view {
    //   width: 15.35px;
    //   height: 22px;
    //   margin-right: 30px;
    // }
  }

  .xx_address_class {
    width: 100%;
    height: 48px;
    background: white;
    display: flex;
    margin-left: 12px;
    flex-direction: row;
    align-items: center;
    span {
      width: 86.5px;
      font-size: 15px;
      color: #333333;
    }
    input {
      width: 252.5px;
      height: 24px;
      border: none;
      font-size: 15px;
      margin-left: 15px;
      color: black;
    }
    input::input-placeholder {
      color: #cccccc;
    }
  }
  .upload_photo_view {
    width: 100%;
    height: 128px;
    display: flex;
    flex-direction: column;
    img {
      width: 110px;
      height: 70px;
      margin: 16px 0px 8px 12px;
    }
    span {
      font-size: 14px;
      color: #acacac;
      margin-left: 12px;
    }
  }
  .switch_view {
    height: 48px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 0.5px solid #eeeeee;
    margin-left: 12px;
    justify-content: space-between;
    span {
      font-size: 15px;
      color: #3f3f3f;
    }
  }
}
.bottom_btn_sub_view {
  width: 100%;
  height: 57px;
  background: white;
  position: fixed;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 36px;
    margin-left: 20px;
    margin-right: 20px;
    background: linear-gradient(to right, #01b15b, #49d0a2);
    border-radius: 18px;
    border: none;
    color: white;
    font-size: 13px;
  }
}
</style>
